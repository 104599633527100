import React from 'react'

const ProductUseCases = () => {
  return (
    <div style={{ maxWidth: "35rem" }}>
      <p className="p-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-3">
        USE CASES
      </p>
      <ul className="col-12 col-md-10 px-3 list-group list-group-flush">
        <li className="list-group-item">
          1. Renewable Energy Integration
        </li>
        <li className="list-group-item">2. Grid Services</li>
        <li className="list-group-item">3. Backup Power</li>
        <li className="list-group-item">4. Microgrids</li>
        <li className="list-group-item">
          5. Commercial and Industrial Applications
        </li>
        <li className="list-group-item">6. Transportation</li>
        <li className="list-group-item">
          7. Residential Applications
        </li>
        <li className="list-group-item">8. Telecommunications</li>
        <li className="list-group-item">9. Military and Defense</li>
        <li className="list-group-item">10. Agriculture</li>
      </ul>
    </div>
  )
}

export default ProductUseCases